import React, { MouseEvent } from 'react'

import errorImg from '../../images/error.png'

import { Container } from '../../style'

type OpenAccountFormProps = {
  handleReturn: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

const Error = ({
  handleReturn,
  className,
}: OpenAccountFormProps) => {
  return (
    <Container className={`d-flex align-items-center ${className}`}>
      <div className='col-12 text-center mx-auto'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 my-3'>
            <img src={errorImg} alt='Imagem de erro' />
          </div>
          <div className='col-12 col-md-9 col-lg-11 my-2 my-md-0'>
            <p className='fs-16 lh-20 text-grayscale--400 mb-3'>
              Ah não! Ocorreu um erro com a sua solicitação...
            </p>
            <p className='fs-16 lh-20 fs-md-18 lh-md-22 text-grayscale--500 mb-0'>
              Verifique a sua conexão com a internet e tente novamente.
              <div className='back'>
                <button
                  onClick={handleReturn}
                  title='Clique aqui para voltar'
                  className='bg-transparent text-orange--extra fw-600 border-0'
                > Clique aqui
                </button> para voltar.
              </div>
            </p>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Error
